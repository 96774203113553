import type {  LoaderFunctionArgs} from '@remix-run/node';

import { invariant } from '@epic-web/invariant';
import { redirect} from '@remix-run/node';
import { match } from 'ts-pattern';

export function loader({ params, request }: LoaderFunctionArgs) {
  invariant(params.userId, 'params.userId should be defined');
  throw redirect(`/my-library/${params.userId}/library`);
}

export const EmptyDescription = (props: {isMe: boolean; library?: 'books' | 'library'}) => {
  return (
    <div className='grid gap-5 justify-center text-center'>
      <div className='grid gap-3'>
        <h1 className='text-gray-90 text-subtitle-1-bold-desktop font-pretendardBold'>책장이 비어있어요.</h1>
        {
          props.isMe
            ? 
            match(props.library)
              .with('books', () => (
                <>
                  <h2 className='text-gray-60 text-label-2-regular font-pretendardLight'>책장에 도서를 추가하고, <br/>
              다른 사람들과 함께 책 정보를 공유하세요!
                  </h2>
                </>
              ))
              .with('library', () => (
                <>
                  <h2 className='text-gray-60 text-label-2-regular font-pretendardLight'>내가 읽은 책에 코멘트를 남겨 저장할 수 있어요. <br/>
              다른 사람들에게 내가 추천하는 책을 공유해주세요.
                  </h2>
                </>
              ))
              .otherwise(() => (
                <>
                  <h2 className='text-gray-60 text-label-2-regular font-pretendardLight'>북커버리에서 다양한 전문 분야의 사람들이 <br/>
              추천한 책을 찾아볼 수 있어요.
                  </h2>
                </>
              ))
            : 
            <h2 className='text-gray-60 text-label-2-regular font-pretendardLight'>북커버리에서 다양한 전문 분야의 사람들이 <br/>
          추천한 책을 찾아볼 수 있어요.
            </h2>
        }
      </div>
    </div>
  );
};